import { mapHelper } from 'common/utils'

// 按钮列表
const btnOps = [
  {
    text: '添加新文件夹',
    title: '添加新文件夹',
    code: 1,
    value: 'addSectionBtn'
  },
  {
    text: '重命名',
    title: '重命名',
    code: 2,
    value: 'editBtn'
  },
  {
    text: '删除',
    title: '删除',
    code: 3,
    value: 'deteleBtn'
  },
  {
    text: '移动',
    title: '移动',
    code: 4,
    value: 'moveBtn'
  }
]

// 发布端
const port = [
  {
    text: '后台',
    checked: false,
    value: 0
  },
  {
    text: '物管端',
    checked: false,
    value: 1
  },
  {
    text: '业主端',
    checked: false,
    value: 2
  },
  {
    text: '商家端',
    checked: false,
    value: 3
  }
]
const {
  map: portMap,
  setOps: portOps
} = mapHelper.setMap(port)

// 账号状态
const status = [
  {
    text: '未发布',
    value: 0
  },
  {
    text: '已发布',
    value: 1
  }
]
const {
  map: statusMap,
  setOps: statusOps
} = mapHelper.setMap(status)

const fileUrl = './img/'
const treeIconMap = {
  files: {
    iconOpen: require(`${fileUrl}folderClose.png`),
    iconClose: require(`${fileUrl}folderOpen.png`)
  },
  file: {
    iconOpen: require(`${fileUrl}file.png`),
    iconClose: require(`${fileUrl}file.png`)
  }
}

export {
  btnOps,
  portOps,
  portMap,
  statusOps,
  statusMap,
  treeIconMap
}
