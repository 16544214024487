/** 管理组织 */
// 获取组织树
const getEnterOrgTreeListURL = `${API_CONFIG.butlerBaseURL}documentTree/getOrgTreeList`
// 添加文件夹
const addOrgNodeURL = `${API_CONFIG.butlerBaseURL}documentTree/addOrgNode`
// 删除文件夹
const deleOrgNodeURL = `${API_CONFIG.butlerBaseURL}documentTree/deleteDocumentTreeById`
// 移动文件夹
const updateOrgNodeURL = `${API_CONFIG.butlerBaseURL}documentTree/moveOrgNode`
// 发布
const releaseURL = `${API_CONFIG.butlerBaseURL}documentManagement/updatePublishStatus`
// 编辑文件夹
const updateOrgInformationURL = `${API_CONFIG.butlerBaseURL}documentTree/updateOrgInfo`
// 查询节点下的文件数量
const searchFileNumURL = `${API_CONFIG.butlerBaseURL}documentTree/selectDocumentNumByOrgId`
// 查询标签接口
const getLabelURL = `${API_CONFIG.butlerBaseURL}documentManagement/select2Label`

/** 文件 */
// 查询成员列表
const selectEnterpriseUserInfoListURL = `${API_CONFIG.butlerBaseURL}documentManagement/selectDocumentFileInfoList`
// 导出成员列表
// const exportURL = `${API_CONFIG.butlerBaseURL}enterpriseUserInfo/exportEnterpriseUserInfoList`
// 批量移动文件
const batchMoveURL = `${API_CONFIG.butlerBaseURL}documentTree/updateFileOrgNodes`
// 删除文件、批量删除文件
const batchDeleteURL = `${API_CONFIG.butlerBaseURL}documentManagement/batchDelete`
// 新增/编辑文件
const addFileURL = `${API_CONFIG.butlerBaseURL}documentManagement/addFile`
// 文件详情列表
const getDetailURL = `${API_CONFIG.butlerBaseURL}documentManagement/detail`
// 获取搜索标签列表
const getLabelListURL = `${API_CONFIG.shopBaseURL}labelShu/list?labelType=3&labelStatus=1`
// 获取查看树
const getViewOrgTreeListURL = `${API_CONFIG.shopBaseURL}documentTree/getOrgTreeDetailList`

export {
  getLabelListURL,
  getEnterOrgTreeListURL,
  addOrgNodeURL,
  updateOrgNodeURL,
  batchMoveURL,
  updateOrgInformationURL,
  searchFileNumURL,
  releaseURL,
  addFileURL,
  getDetailURL,
  selectEnterpriseUserInfoListURL,
  // exportURL,
  deleOrgNodeURL,
  getLabelURL,
  batchDeleteURL,
  getViewOrgTreeListURL
}
